import React, { useState, useEffect } from "react";
import styled from "styled-components";
import Lockr from "lockr";

import { GetFireWebToCase } from "../../services/SalesForceService";
import { PutAuxiliaryDocuments } from "../../services/AuxiliaryDocuments";

// components
import Header from "../Header";
import NavBar from "./NavBar";
import { Container, Row, Col } from "react-bootstrap";
import Feedback from "../../components/painel/Feedback";
import LoaderAnimation from "../../components/LoaderAnimation";
import Sidebar from "../painel/Sidebar";
import { MobileView, BrowserView } from "react-device-detect";

const Content = (props) => {
  const [clientId, setClientId] = useState("");
  const [contractId, setContractId] = useState("");
  const [isLoading, setLoading] = useState(false);
  const [completedStepsOpen, setCompletedStepsOpen] = useState(false);
  const [selectedStepIndex, setSelectedStepIndex] = useState(null);
  const [stepCurrent, setStepCurrent] = useState("Análise");
  const [caseStatus, setCaseStatus] = useState({
    Status: "New",
    CaseNumber: "20240702263413",
    Reason: "Carro Reserva",
    Fase__c: "Direcionamento",
    CreatedDate: "2024-07-02T13:23:12.000+0000",
  });

  const handleDivClick = (index) => {
    setSelectedStepIndex((prevIndex) => (prevIndex === index ? null : index));
  };

  const handleCompletedStepsClick = () => {
    setCompletedStepsOpen(!completedStepsOpen);
  };

  const [steps, setSteps] = useState([
    {
      title: "Abertura",
      description: "",
      completed: true,
      status: "concluido",
    },
    {
      title: "Documentação",
      description: "",
      completed: true,
      status: "concluido",
    },
    {
      title: "Análise",
      description:
        "Nesta etapa, verificamos suas coberturas, o evento relatado e os documentos enviados.",
      completed: false,
      status: "atual",
    },
    {
      title: "Direcionamento",
      description:
        "Nesta etapa, determinamos a extensão do dano e sugerimos a oficina credenciada mais próxima.",
      completed: false,
      status: "futuros",
    },
    {
      title: "Regulação",
      description:
        "Nesta etapa, avaliamos os custos de mão de obra e peças para autorizar o conserto do veículo.",
      completed: false,
      status: "futuros",
    },
    {
      title: "Participação Obrigatória",
      description:
        "Nesta etapa, comunicamos o valor do pagamento obrigatório e oferecemos instruções para realizar o pagamento.",
      completed: false,
      status: "futuros",
    },
    {
      title: "Veículo em Reparo",
      description:
        "Estamos monitorando de perto o reparo do seu veículo para garantir que tudo corra conforme o planejado.",
      completed: false,
      status: "futuros",
    },
    {
      title: "Finalização",
      description: "Make sure to complete step 3",
      completed: false,
      status: "futuros",
    },
  ]);

  const nonCompletedStepsCount = steps.filter((step) => !step.completed).length;

  const completedStepsCount = steps.filter((step) => step.completed).length;

  useEffect(() => {
    async function loadData() {
      let value;
      let clientData;

      if (props.nCtrId) {
        clientData = Lockr.get("ClientData");

        value = clientData.customerCars.filter((contracts) => {
          return contracts.contractInfo.nCtrId === props.nCtrId;
        });

        Lockr.set("ClientData", {
          IdCustomerSelectedCar: value[0].value,
          customerSelectedCar: value[0],
        });

        const customerSelectedCar = clientData.customerSelectedCar;
        const contractInfo = customerSelectedCar.contractInfo;
        setClientId(contractInfo.bpName);
        setContractId(contractInfo.nCtrId);
      } else {
        clientData = Lockr.get("ClientData");
        const customerSelectedCar = clientData.customerSelectedCar;
        const contractInfo = customerSelectedCar.contractInfo;
        setClientId(contractInfo.bpName);
        setContractId(contractInfo.nCtrId);
        console.log(clientData);
      }
    }

    loadData();
  }, [props.nCtrId]);

  useEffect(() => {
    setStepCurrent(caseStatus.Fase__c);

    if (stepCurrent !== null) {
      definedStepsStatus(stepCurrent);
    }
  }, [caseStatus.Fase__c, stepCurrent]);

  const definedStepsStatus = (stepCurrent) => {
    let currentIndex;
    const updatedSteps = steps.map((step, index) => {
      switch (stepCurrent) {
        case "Análise":
          currentIndex = steps.findIndex((step) => step.title === "Análise");
          if (step.title === "Análise") {
            return { ...step, status: "atual" };
          } else if (index < currentIndex) {
            return { ...step, status: "concluido", completed: true };
          } else {
            return { ...step, status: "futuros", completed: false };
          }
        case "Direcionamento":
          currentIndex = steps.findIndex(
            (step) => step.title === "Direcionamento"
          );
          if (step.title === "Direcionamento") {
            return { ...step, status: "atual" };
          } else if (index < currentIndex) {
            return { ...step, status: "concluido", completed: true };
          } else {
            return { ...step, status: "futuros", completed: false };
          }
        case "Regulação":
          currentIndex = steps.findIndex((step) => step.title === "Regulação");
          if (step.title === "Regulação") {
            return { ...step, status: "atual" };
          } else if (index < currentIndex) {
            return { ...step, status: "concluido", completed: true };
          } else {
            return { ...step, status: "futuros", completed: false };
          }
        case "Comunicação Cliente":
          currentIndex = steps.findIndex(
            (step) => step.title === "Participação Obrigatória"
          );
          if (step.title === "Participação Obrigatória") {
            return { ...step, status: "atual" };
          } else if (index < currentIndex) {
            return { ...step, status: "concluido", completed: true };
          } else {
            return { ...step, status: "futuros", completed: false };
          }
        case "Acompanhamento":
        case "Pagamento":
        case "Monitorar Pagamento":
          currentIndex = steps.findIndex(
            (step) => step.title === "Veículo em Reparo"
          );
          if (step.title === "Veículo em Reparo") {
            return { ...step, status: "atual" };
          } else if (index < currentIndex) {
            return { ...step, status: "concluido", completed: true };
          } else {
            return { ...step, status: "futuros", completed: false };
          }
        case "Concluído":
          if (step.title === "Finalização") {
            return { ...step, status: "concluido", completed: true };
          } else if (index < currentIndex) {
            return { ...step, status: "concluido", completed: true };
          }
          break;
        default:
          console.log(`Sorry, we are out of ${stepCurrent}.`);
          return step;
      }
    });

    setSteps(updatedSteps);
  };

  return (
    <React.Fragment>
      <LoaderAnimation show={isLoading} />
      <h3 className="loovi-header-title title-color">Solicitações</h3>
      <div>
        <p className="update-title">Atualização</p>
      </div>
      <>
        <p className="update-title">Etapas</p>
        <>
          <link
            href="https://fonts.googleapis.com/icon?family=Material+Icons"
            rel="stylesheet"
          />
          {completedStepsCount > 0 && (
            <>
              {completedStepsOpen === true ? (
                <div className="box-completed">
                  <div className="box-steps">
                    {steps
                      .filter((step) => step.completed)
                      .map((step, i) => (
                        <div
                          className="steps-content"
                          key={i}
                          style={{ height: "100%" }}
                        >
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              flexDirection: "column",
                            }}
                          >
                            {i !== 0 && (
                              <div
                                className="step-bar"
                                style={{
                                  background: step.completed
                                    ? "#5a78ff"
                                    : "#E5E5E5",
                                  marginBottom: "-0.1rem",
                                }}
                              ></div>
                            )}
                            <div
                              className="material-icons"
                              style={{
                                color: step.completed ? "#5a78ff" : "#E5E5E5",
                              }}
                            >
                              check_circle
                            </div>
                            <div
                              className="step-bar"
                              style={{
                                background: step.completed
                                  ? "#5a78ff"
                                  : "#E5E5E5",
                                marginTop: "-0.1rem",
                              }}
                            ></div>
                          </div>
                        </div>
                      ))}
                  </div>
                  <div className="steps-completed-content">
                    <p
                      onClick={handleCompletedStepsClick}
                      className="material-icons"
                      style={{
                        fontSize: "24px",
                        textAlign: "end",
                        color: "#5a78ff",
                        marginTop: "0.2rem",
                        marginBottom: "0.2rem",
                      }}
                    >
                      keyboard_arrow_up
                    </p>
                    {steps
                      .filter((step) => step.completed)
                      .map((step, i) => (
                        <div className="steps-content" key={i}>
                          <div
                            className="step-completed"
                            style={{ marginTop: i !== 0 ? "1rem" : "0rem" }}
                          >
                            <p className="step-current-title">{step.title}</p>
                            {selectedStepIndex === i && (
                              <p className="step-current-description">
                                {step.description}
                              </p>
                            )}
                            {step.title === "Abertura" ? (
                              <div className="step-current-info">
                                <p>Concluído</p>
                                <p
                                  //onClick={handleCompletedStepsClick}
                                  className="material-icons"
                                  style={{
                                    fontSize: "12px",
                                    textAlign: "end",
                                    color: "#ffffff",
                                    marginLeft: '0.2rem'
                                  }}
                                >
                                  arrow_forward
                                </p>
                              </div>
                            ) : step.title === "Documentação" ? (
                              <div className="step-current-info">
                                <p>Concluído</p>
                                <p
                                  //={handleCompletedStepsClick}
                                  className="material-icons"
                                  style={{
                                    fontSize: "12px",
                                    textAlign: "end",
                                    color: "#ffffff",
                                    marginLeft: '0.2rem'
                                  }}
                                >
                                  arrow_forward
                                </p>
                              </div>
                            ) : (
                              <div className="step-current-info">
                                <p>Concluído</p>
                              </div>
                            )}
                          </div>
                        </div>
                      ))}
                  </div>
                </div>
              ) : (
                <div
                  className="box-completed"
                  onClick={handleCompletedStepsClick}
                >
                  <div className="box-steps">
                    <div className="steps-content" style={{ height: "100%" }}>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          flexDirection: "column",
                        }}
                      >
                        <div
                          className="material-icons"
                          style={{
                            color: "#5a78ff",
                          }}
                        >
                          check_circle
                        </div>
                        <div
                          className="step-bar"
                          style={{
                            background: "#5a78ff",
                            marginTop: "-0.1rem",
                          }}
                        ></div>
                      </div>
                    </div>
                  </div>
                  <div className="step-completed-content">
                    <p className="step-values-completed">
                      {completedStepsCount > 1
                        ? `${completedStepsCount} etapas concluídas`
                        : `${completedStepsCount} etapa concluída`}
                    </p>
                    <p
                      className="material-icons"
                      style={{
                        fontSize: "24px",
                        textAlign: "end",
                        color: "#5a78ff",
                        marginTop: "0.2rem",
                        marginBottom: "0.2rem",
                      }}
                    >
                      keyboard_arrow_down
                    </p>
                  </div>
                </div>
              )}
            </>
          )}
          {steps
            .filter((step) => !step.completed)
            .map((step, i) => (
              <div className="steps-content" key={i}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "column",
                  }}
                >
                  {completedStepsCount > 0 && (
                    <div
                      className="step-bar"
                      style={{
                        background:
                          step.status === "atual" ? "#5a78ff" : "#E5E5E5",
                        marginBottom: "-0.1rem",
                      }}
                    ></div>
                  )}
                  <div
                    className="material-icons"
                    style={{
                      color: step.status === "atual" ? "#5a78ff" : "#E5E5E5",
                    }}
                  >
                    radio_button_unchecked
                  </div>
                  {i === nonCompletedStepsCount - 1 ? (
                    <></>
                  ) : (
                    <div
                      className="step-bar"
                      style={{
                        background:
                          step.status === "atual" ? "#5a78ff" : "#E5E5E5",
                        marginTop: "-0.1rem",
                      }}
                    ></div>
                  )}
                </div>
                {step.status === "atual" ? (
                  <div
                    className="step-current"
                    onClick={() => handleDivClick(i)}
                    style={{ marginTop: "1rem" }}
                  >
                    <p className="step-current-title">{step.title}</p>
                    {selectedStepIndex === i && (
                      <p className="step-current-description">
                        {step.description}
                      </p>
                    )}
                    <div className="step-current-info">
                      <p>Em andamento</p>
                    </div>
                  </div>
                ) : (
                  <div className="step-disabled" style={{ marginTop: "1rem" }}>
                    <p className="step-disabled-title">{step.title}</p>
                  </div>
                )}
              </div>
            ))}
        </>
      </>
    </React.Fragment>
  );
};

export default Content;

export class Consultation extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      description: "",
      file: "",
    };
  }

  handleChange = (event) => {
    this.setState({ description: event.target.value });
  };

  handleChangeFile = (event) => {
    this.setState({ file: event.target.value });
  };

  render() {
    const { description, file } = this.state;
    const { contactPhone, setLoading } = this.props;
    const values = {
      description,
      file,
      contactPhone,
      setLoading,
    };
    return (
      <Section>
        <Header>
          <NavBar backButton blueArrow />
        </Header>
        <MobileView>
          <Container>
            <Row>
              <Col sm={12} md={{ span: 6, offset: 3 }}>
                <Content
                  values={values}
                  handleChange={this.handleChange}
                  handleChangeFile={this.handleChangeFile}
                />
              </Col>
            </Row>
          </Container>
        </MobileView>
        <BrowserView>
          <Container fluid>
            <Row>
              <div className="loovi-fixed-side-column">
                <Sidebar />
              </div>
              <div className="loovi-fluid-column">
                <Col sm={12} md={6}>
                  <div className="loovi-descktop-padding">
                    <Content
                      values={values}
                      handleChange={this.handleChange}
                      handleChangeFile={this.handleChangeFile}
                    />
                  </div>
                </Col>
              </div>
            </Row>
          </Container>
        </BrowserView>
      </Section>
    );
  }
}

const Section = styled.section`
  position: relative;
  font-size: 15px;
  color: #393043 !important;

  p {
    margin: 0;
    font-size: 16px;
    letter-spacing: -0.32px;
    line-height: 21px;
  }

  h4 {
    font-size: 15px;
    color: #899299;
    letter-spacing: -0.32px;
    text-align: center;
    line-height: 21px;
  }

  .loovi-descktop-padding {
    h3,
    h4 {
      text-align: left;
    }
  }

  text-color-Ivoce {
    color: #393043 !important;
  }

  .title-color {
    color: #a1a4ab !important;
  }

  .update-title {
    color: #515151;
    font-size: 16px;
    font-weight: 700;
    margin-top: 3rem;
  }

  .step-bar {
    height: 100%;
    width: 2px;
  }

  .steps-content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
  }

  .step-current {
    width: 268px;
    min-height: 64px;
    border-radius: 12px;
    padding: 12px;
    background: #5a78ff;
    box-shadow: 2px 2px 24px 0px #0000000a;
    box-shadow: 4px 4px 24px 0px #00000005;
    flex-direction: column;
    display: flex;
    justify-content: space-between;
  }

  .step-current-title {
    color: #ffffff;
    font-size: 14px;
    font-weight: 700;
  }

  .step-current-info {
    width: 100%;
    flex-direction: row;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  .step-current-info p {
    color: #ffffff;
    font-size: 12px;
    font-weight: 400;
  }

  .step-disabled {
    width: 268px;
    min-height: 64px;
    border-radius: 12px;
    padding: 12px;
    background: #eef2fa;
    box-shadow: 2px 2px 24px 0px #0000000a;
    box-shadow: 4px 4px 24px 0px #00000005;
    flex-direction: column;
    display: flex;
    justify-content: space-between;
    margin-top: 1.5rem;
  }

  .step-disabled-title {
    color: #999999;
    font-size: 14px;
    font-weight: 700;
  }

  .step-current-description {
    font-weight: 400;
    font-size: 12px;
    color: #ffffff;
    line-height: 14.52px;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }

  .box-completed {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
  }

  .box-steps {
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .steps-completed-content {
    width: 268px;
    background: #eef2fa;
    display: flex;
    flex-direction: column;
    padding: 8px 12px 12px 12px;
    border-radius: 12px;
    border: 1px solid #e0e0ee;
  }

  .step-completed {
    width: 244px;
    min-height: 64px;
    border-radius: 12px;
    padding: 12px;
    background: #5a78ff;
    box-shadow: 2px 2px 24px 0px #0000000a;
    box-shadow: 4px 4px 24px 0px #00000005;
    flex-direction: column;
    display: flex;
    justify-content: space-between;
  }

  .step-current-title {
    color: #ffffff;
    font-size: 14px;
    font-weight: 700;
  }

  .step-completed-content {
    width: 268px;
    height: 33px;
    background: #eef2fa;
    padding: 8px 12px;
    border-radius: 8px;
    box-shadow: 2px 2px 24px 0px #0000000a;
    box-shadow: 4px 4px 24px 0px #00000005;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  .step-values-completed {
    color: #254dff;
    font-size: 14px;
    font-weight: 700;
  }
`;
