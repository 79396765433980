// Dependencias

import React, { useState, useEffect } from "react";
import styled from "styled-components";
import useForm from "react-hook-form";
import * as masks from "../../helpers/masks";

// Componentes

import Header from "../Header";
import NavBar from "./NavBar";
import { Container, Row, Col } from "react-bootstrap";
import Feedback from "../../components/painel/Feedback";
import LoaderAnimation from "../../components/LoaderAnimation";
import Sidebar from "../painel/Sidebar";
import { MobileView, BrowserView } from "react-device-detect";
import Lockr from "lockr";

import { FormUpdateData } from "./support/UpdateData";
import { FormInvoiceDispute } from "./support/InvoiceDispute";
import { FormOmbudsman } from "./support/Ombudsman";
import { FormCancellation } from "./support/Cancellation";
import { FormDevice } from "./support/Device";
import { FormExpirationChange } from "./support/ExpirationChange";
import { FormVehicleChanges } from "./support/VehicleChanges";
import { FormBackupCar } from "./support/BackupCar";
import { FormChangePlan } from "./support/ChangePlan";

const Form = (props) => {
  const { register, errors, clearError, setError, setValue } = useForm();
  const [isLoading, setLoading] = useState(false);

  const [phoneContact, setPhoneContact] = useState("");

  useEffect(() => {
    async function loadData() {
      let value;
      let clientData;

      console.log('nctrid ',props.nCtrId)

      if (props.nCtrId) {
        clientData = Lockr.get("ClientData");
        //console.log(clientData);
        //console.log(clientData.customerCars.filter(contracts  => {return contracts.contractInfo.nCtrId === props.nCtrId;}));

        value = clientData.customerCars.filter((contracts) => {
          return contracts.contractInfo.nCtrId === props.nCtrId;
        });

        //console.log(value);
        //console.log(value[0].value);

        Lockr.set("ClientData", {
          IdCustomerSelectedCar: value[0].value,
          customerCars: clientData.customerCars,
          customerSelectedCar: value[0],
          customerInfo: clientData.customerInfo,
        });

        clientData = Lockr.get("ClientData");
        //console.log(clientData);
        console.log('',clientData)
      }
    }

    if (props.nCtrId) {
      loadData();
    }
  }, [props.nCtrId]);

  const handlePhoneChange = (e) => {
    let value = e.target.value.replace(/[\D]/g, "");
    if (parseInt(value[2]) !== 9 || value.length < 11)
      setError("contactPhone", "invalid", "Número de celular inválido");
    else clearError("contactPhone");

    value = masks.phone(value);
    setValue("contactPhone", value);
    setPhoneContact(value);
    props.handleChange(e);
  };

  return (
    <React.Fragment>
      <LoaderAnimation show={isLoading} />
      <h3 className="loovi-header-title loovi-color-2">Solicitações</h3>
      <p className="loovi-color-grey-2">
        Precisa de ajuda? Envie sua solicitação para o time da Loovi que
        retornaremos o mais rápido possível.
      </p>
      <span className="loovi-spacer"></span>
      <h4 className="loovi-color-grey-2">Preencha os campos abaixo</h4>
      <>
        <label>
          <span className="text-color-Ivoce">TIPO DE SOLICITAÇÃO</span>
          <select
            name="supportAreaCheck"
            ref={register({ required: true })}
            className="loovi-select"
            value={props.values.supportArea.value}
            onChange={(e) => {
              const val = e.target.value;
              props.handleSelectChange(
                { value: val, label: val },
                "supportArea"
              );
              if (props.values.supportArea.value !== "")
                return clearError("supportAreaCheck");
            }}
          >
            {props.values.supportAreas.map((area, index) => (
              <option key={index} value={area.value}>
                {area.label}
              </option>
            ))}
          </select>
          {errors.supportAreaCheck && (
            <span className="loovi-msg-error">Selecione uma área</span>
          )}
        </label>
        <label>
          <span className="text-color-Ivoce">TELEFONE PARA CONTATO</span>
          <input
            className={
              errors.contactPhone
                ? "loovi-field loovi-field-error"
                : "loovi-field"
            }
            name="contactPhone"
            onChange={handlePhoneChange}
            ref={register({
              required: true,
              validate: (input) => {
                const value = input.replace(/[\D]/g, "");
                return parseInt(value[2]) === 9 && value.length >= 11;
              },
            })}
            type="text"
          />
          {errors.contactPhone && (
            <span className="loovi-msg-error">
              Preencha o campo acima com um telefone válido.
            </span>
          )}
        </label>
        {props.values.supportArea.value === "Alteração de Plano" && (
          <FormChangePlan
            contactPhone={phoneContact}
            setLoading={setLoading}
            proceed={props.proceed}
          />
        )}
        {props.values.supportArea.value === "Alteração de Dados Cadastrais" && (
          <FormUpdateData
            contactPhone={phoneContact}
            setLoading={setLoading}
            proceed={props.proceed}
          />
        )}
        {props.values.supportArea.value === "Alteração de Vencimento" && (
          <FormExpirationChange
            contactPhone={phoneContact}
            setLoading={setLoading}
            proceed={props.proceed}
          />
        )}
        {props.values.supportArea.value === "Suporte Rastreador" && (
          <FormDevice
            contactPhone={phoneContact}
            setLoading={setLoading}
            proceed={props.proceed}
          />
        )}
        {props.values.supportArea.value === "Contestação de Fatura" && (
          <FormInvoiceDispute
            contactPhone={phoneContact}
            setLoading={setLoading}
            proceed={props.proceed}
          />
        )}
        {props.values.supportArea.value === "Ouvidoria" && (
          <FormOmbudsman
            contactPhone={phoneContact}
            setLoading={setLoading}
            proceed={props.proceed}
          />
        )}
        {props.values.supportArea.value === "Cancelamento" && (
          <FormCancellation
            contactPhone={phoneContact}
            setLoading={setLoading}
            proceed={props.proceed}
          />
        )}
        {props.values.supportArea.value === "Troca de Veículo/ Correção de Placa" &&
          <FormVehicleChanges
            contactPhone={phoneContact}
            setLoading={setLoading}
            proceed={props.proceed}
          />
        }
        {props.values.supportArea.value === "Carro Reserva" &&
          <FormBackupCar
            contactPhone={phoneContact}
            setLoading={setLoading}
            proceed={props.proceed}
          />
        }
      </>
    </React.Fragment>
  );
};

export class Support extends React.Component {
  constructor(props) {
    super(props);
    if (
      this.props.match.path === "/suporte" ||
      this.props.match.path === "/suporte/:nCtrId"
    ) {
      this.state = {
        step: 1,
        supportAreas: [
          { value: "", label: "-- Nenhum --" },
          {
            value: "Alteração de Dados Cadastrais",
            label: "Alteração de Dados Cadastrais",
          },
          { value: "Alteração de Plano", label: "Alteração de Plano" },
          {
            value: "Alteração de Vencimento",
            label: "Alteração de Vencimento",
          },
          { value: "Carro Reserva", label: "Carro Reserva" },
          {
            value: "Troca de Veículo/ Correção de Placa",
            label: "Troca de Veículo/ Correção de Placa",
          },
          { value: "Contestação de Fatura", label: "Contestação de Fatura" },
          { value: "Ouvidoria", label: "Ouvidoria" },
          { value: "Cancelamento", label: "Cancelamento" },
        ],
        supportArea: { value: "", label: "-- Nenhum --" },
        supportAreaCheck: {},
        supportSubject: "",
        supportDescription: "",
        contactPhone: "",
      };
    } else if (this.props.match.path === "/suporte/:nCtrId/:upSell") {
      if (this.props.match.params.upSell === "0") {
        this.state = {
          step: 1,
          supportAreas: [
            { value: "", label: "-- Nenhum --" },
            {
              value: "Alteração de Dados Cadastrais",
              label: "Alteração de Dados Cadastrais",
            },
            { value: "Alteração de Plano", label: "Alteração de Plano" },
            {
              value: "Alteração de Vencimento",
              label: "Alteração de Vencimento",
            },
            { value: "Carro Reserva", label: "Carro Reserva" },
            {
              value: "Troca de Veículo/ Correção de Placa",
              label: "Troca de Veículo/ Correção de Placa",
            },
            { value: "Suporte Rastreador", label: "Suporte Rastreador" },
            { value: "Contestação de Fatura", label: "Contestação de Fatura" },
            { value: "Ouvidoria", label: "Ouvidoria" },
            { value: "Cancelamento", label: "Cancelamento" },
          ],
          supportArea: { value: "", label: "-- Nenhum --" },
          supportAreaCheck: {},
          supportSubject: "",
          supportDescription: "",
          contactPhone: "",
        };
      } else if (this.props.match.params.upSell === "1") {
        this.state = {
          step: 1,
          supportAreas: [
            { value: "Alteração de Plano", label: "Alteração de Plano" },
          ],
          supportArea: {
            value: "Alteração de Plano",
            label: "Alteração de Plano",
          },
          supportAreaCheck: {},
          supportSubject: "",
          supportDescription: "",
          contactPhone: "",
        };
      }
    }
  }

  proceed = () => {
    this.setState({ step: 2 });
  };

  handleChange = (input) => (event) => {
    this.setState({ [input]: event.target.value });
  };

  handleSelectChange = (selectedOption) => {
    this.setState({ supportArea: selectedOption });
  };

  render() {
    const {
      step,
      supportArea,
      supportAreas,
      supportSubject,
      supportDescription,
      contactPhone,
    } = this.state;

    const values = {
      step,
      supportArea,
      supportAreas,
      supportSubject,
      supportDescription,
      contactPhone,
    };

    switch (step) {
      case 2:
        return (
          <Feedback
            backLink
            feedbackTitle="Suporte"
            alertTitle="Sua solicitação foi enviada com sucesso!"
            contentTitle="Protocolo 1234567890"
          >
            <p>
              Seu chamado foi aberto com sucesso. Nosso prazo de retorno é de
              até 3 dias úteis.
            </p>
            <p>
              Fique atento à sua caixa de email que o nosso suporte irá retornar
              a sua solicitação.
            </p>
          </Feedback>
        );
      default:
        return (
          <Section>
            <Header>
              <NavBar backButton blueArrow />
            </Header>
            <MobileView>
              <Container>
                <Row>
                  <Col sm={12} md={{ span: 6, offset: 3 }}>
                    <Form
                      nCtrId={this.props.match.params.nCtrId}
                      values={values}
                      handleSelectChange={this.handleSelectChange}
                      handleChange={this.handleChange}
                      proceed={this.proceed}
                    />
                  </Col>
                </Row>
              </Container>
            </MobileView>
            <BrowserView>
              <Container fluid>
                <Row>
                  <div className="loovi-fixed-side-column">
                    <Sidebar />
                  </div>
                  <div className="loovi-fluid-column">
                    <Col sm={12} md={6}>
                      <div className="loovi-descktop-padding">
                        <Form
                          nCtrId={this.props.match.params.nCtrId}
                          values={values}
                          handleSelectChange={this.handleSelectChange}
                          handleChange={this.handleChange}
                          proceed={this.proceed}
                        />
                      </div>
                    </Col>
                  </div>
                </Row>
              </Container>
            </BrowserView>
          </Section>
        );
    }
  }
}

const Section = styled.section`
  position: relative;
  font-size: 15px;

  p {
    margin: 0;
    font-size: 16px;
    letter-spacing: -0.32px;
    line-height: 21px;
  }

  h4 {
    font-size: 15px;
    color: #899299;
    letter-spacing: -0.32px;
    text-align: center;
    line-height: 21px;
  }

  .loovi-descktop-padding {
    h3,
    h4 {
      text-align: left;
    }
  }

  .address-cep {
    flex-direction: row;
    display: flex;
    justify-content: space-between;
  }

  .address-cep input {
    width: 96% !important;
  }

  .checkbox {
    color: #a1a4ab !important;
    display: flex;
    align-items: center;
  }

  .text-color-Ivoce {
    color: #393043 !important;
  }
`;
